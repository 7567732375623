import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"
import { ToastContainer } from "react-toastify"
export const AppContext = React.createContext([{}, () => {}])

export const AppProvider = ({ children }) => {
  const isBrowser = typeof window !== "undefined"

  const [cart, setCart] = useState(null)

  useEffect(() => {
    if (isBrowser) {
      const cartData = localStorage?.getItem("woo-next-cart-wallcraft")
      setCart(!isEmpty(cartData) ? JSON.parse(cartData) : "")
    }
  }, [isBrowser])

  return (
    <AppContext.Provider value={[cart, setCart]}>
      <ToastContainer />
      {children}
    </AppContext.Provider>
  )
}
