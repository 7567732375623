exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestseller-js": () => import("./../../../src/pages/bestseller.js" /* webpackChunkName: "component---src-pages-bestseller-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dla-architektow-js": () => import("./../../../src/pages/dla-architektow.js" /* webpackChunkName: "component---src-pages-dla-architektow-js" */),
  "component---src-pages-dla-dystrybutorow-js": () => import("./../../../src/pages/dla-dystrybutorow.js" /* webpackChunkName: "component---src-pages-dla-dystrybutorow-js" */),
  "component---src-pages-do-pobrania-js": () => import("./../../../src/pages/do-pobrania.js" /* webpackChunkName: "component---src-pages-do-pobrania-js" */),
  "component---src-pages-dystrybutorzy-js": () => import("./../../../src/pages/dystrybutorzy.js" /* webpackChunkName: "component---src-pages-dystrybutorzy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kleje-do-tapet-js": () => import("./../../../src/pages/kleje-do-tapet.js" /* webpackChunkName: "component---src-pages-kleje-do-tapet-js" */),
  "component---src-pages-konfigurator-js": () => import("./../../../src/pages/konfigurator.js" /* webpackChunkName: "component---src-pages-konfigurator-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-konto-js": () => import("./../../../src/pages/konto.js" /* webpackChunkName: "component---src-pages-konto-js" */),
  "component---src-pages-koszyk-js": () => import("./../../../src/pages/koszyk.js" /* webpackChunkName: "component---src-pages-koszyk-js" */),
  "component---src-pages-logowanie-js": () => import("./../../../src/pages/logowanie.js" /* webpackChunkName: "component---src-pages-logowanie-js" */),
  "component---src-pages-metody-platnosci-js": () => import("./../../../src/pages/metody-platnosci.js" /* webpackChunkName: "component---src-pages-metody-platnosci-js" */),
  "component---src-pages-moje-konto-js": () => import("./../../../src/pages/moje-konto.js" /* webpackChunkName: "component---src-pages-moje-konto-js" */),
  "component---src-pages-moje-konto-zamowienie-js": () => import("./../../../src/pages/moje-konto/zamowienie.js" /* webpackChunkName: "component---src-pages-moje-konto-zamowienie-js" */),
  "component---src-pages-montaz-js": () => import("./../../../src/pages/montaz.js" /* webpackChunkName: "component---src-pages-montaz-js" */),
  "component---src-pages-newsletter-potwierdzenie-js": () => import("./../../../src/pages/newsletter-potwierdzenie.js" /* webpackChunkName: "component---src-pages-newsletter-potwierdzenie-js" */),
  "component---src-pages-nowosci-js": () => import("./../../../src/pages/nowosci.js" /* webpackChunkName: "component---src-pages-nowosci-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-outlet-js": () => import("./../../../src/pages/outlet.js" /* webpackChunkName: "component---src-pages-outlet-js" */),
  "component---src-pages-podsumowanie-js": () => import("./../../../src/pages/podsumowanie.js" /* webpackChunkName: "component---src-pages-podsumowanie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-probki-js": () => import("./../../../src/pages/probki.js" /* webpackChunkName: "component---src-pages-probki-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-regulamin-promocji-js": () => import("./../../../src/pages/regulamin-promocji.js" /* webpackChunkName: "component---src-pages-regulamin-promocji-js" */),
  "component---src-pages-rejestracja-js": () => import("./../../../src/pages/rejestracja.js" /* webpackChunkName: "component---src-pages-rejestracja-js" */),
  "component---src-pages-rejestracja-konto-aktywowane-js": () => import("./../../../src/pages/rejestracja-konto-aktywowane.js" /* webpackChunkName: "component---src-pages-rejestracja-konto-aktywowane-js" */),
  "component---src-pages-rejestracja-potwierdzenie-js": () => import("./../../../src/pages/rejestracja-potwierdzenie.js" /* webpackChunkName: "component---src-pages-rejestracja-potwierdzenie-js" */),
  "component---src-pages-resetowanie-hasla-js": () => import("./../../../src/pages/resetowanie-hasla.js" /* webpackChunkName: "component---src-pages-resetowanie-hasla-js" */),
  "component---src-pages-resetowanie-hasla-potwierdzenie-js": () => import("./../../../src/pages/resetowanie-hasla-potwierdzenie.js" /* webpackChunkName: "component---src-pages-resetowanie-hasla-potwierdzenie-js" */),
  "component---src-pages-resetowanie-haslo-zmienione-js": () => import("./../../../src/pages/resetowanie-haslo-zmienione.js" /* webpackChunkName: "component---src-pages-resetowanie-haslo-zmienione-js" */),
  "component---src-pages-sposoby-dostawy-js": () => import("./../../../src/pages/sposoby-dostawy.js" /* webpackChunkName: "component---src-pages-sposoby-dostawy-js" */),
  "component---src-pages-system-wet-js": () => import("./../../../src/pages/system-wet.js" /* webpackChunkName: "component---src-pages-system-wet-js" */),
  "component---src-pages-system-zywica-js": () => import("./../../../src/pages/system-zywica.js" /* webpackChunkName: "component---src-pages-system-zywica-js" */),
  "component---src-pages-tapety-js": () => import("./../../../src/pages/tapety.js" /* webpackChunkName: "component---src-pages-tapety-js" */),
  "component---src-pages-tekstury-js": () => import("./../../../src/pages/tekstury.js" /* webpackChunkName: "component---src-pages-tekstury-js" */),
  "component---src-pages-ulubione-js": () => import("./../../../src/pages/ulubione.js" /* webpackChunkName: "component---src-pages-ulubione-js" */),
  "component---src-pages-wyszukiwarka-js": () => import("./../../../src/pages/wyszukiwarka.js" /* webpackChunkName: "component---src-pages-wyszukiwarka-js" */),
  "component---src-pages-zamowienie-js": () => import("./../../../src/pages/zamowienie.js" /* webpackChunkName: "component---src-pages-zamowienie-js" */),
  "component---src-pages-zamowienie-niepowodzenie-js": () => import("./../../../src/pages/zamowienie-niepowodzenie.js" /* webpackChunkName: "component---src-pages-zamowienie-niepowodzenie-js" */),
  "component---src-pages-zamowienie-potwierdzenie-js": () => import("./../../../src/pages/zamowienie-potwierdzenie.js" /* webpackChunkName: "component---src-pages-zamowienie-potwierdzenie-js" */),
  "component---src-pages-zasady-zwrotu-reklamacji-js": () => import("./../../../src/pages/zasady-zwrotu-reklamacji.js" /* webpackChunkName: "component---src-pages-zasady-zwrotu-reklamacji-js" */),
  "component---src-templates-glues-js": () => import("./../../../src/templates/glues.js" /* webpackChunkName: "component---src-templates-glues-js" */),
  "component---src-templates-outlet-js": () => import("./../../../src/templates/outlet.js" /* webpackChunkName: "component---src-templates-outlet-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-samples-js": () => import("./../../../src/templates/samples.js" /* webpackChunkName: "component---src-templates-samples-js" */),
  "component---src-templates-wallpapers-js": () => import("./../../../src/templates/wallpapers.js" /* webpackChunkName: "component---src-templates-wallpapers-js" */)
}

