export { wrapRootElement } from "apollo/wrap-root-element"

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation && !location.hash) {
    setTimeout(
      () => window.scrollTo({ top: 0, left: 0, behavior: "instant" }),
      0
    )
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = "manual"
  const currentPosition = getSavedScrollPosition(location) || [0, 0]

  setTimeout(() => {
    if (location.hash) {
      const item = document.querySelector(`${location.hash}`)
      item?.scrollIntoView({ behavior: "smooth" })
    } else {
      window.scrollTo({
        top: currentPosition[1],
        left: currentPosition[0],
        behavior: "instant",
      })
    }
  }, 10)

  return false
}
